<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Pending Approver Configuration</h1>
            </div>
          </div>

          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate
                                 color="primary"></v-progress-circular>
          </div>
          <v-simple-table>
              <thead>
                <tr>
                    <th class="sticky-col" >
                    S/N
                  </th>
                  <th class="sticky-col" >
                    Module
                  </th>
                  <th class="sticky-col" >
                    Module Type
                  </th>
                  <th class="sticky-col" >
                    Approval Level
                  </th>
                  <th class="sticky-col" >
                    Approvers
                  </th>
                  <th class="sticky-col" style="text-align:center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pendingApprovers" :key="item.id" >
                    <td>{{item.id}}</td>
                    <td >{{item.module}}</td>
                    <td >
                        <span v-if="item.moduleType === null"> N/A</span>
                        <span v-else> {{ item.moduleType }} </span>
                    </td>
                    <td >{{item.approvalCount}}</td>
                    <td >{{item.approvers.join(' , ' )}}</td>
                    <td class="d-flex" >
                        <Button :btnType="'Forward'" :color="'primary'" :label="'Approve'" @onClick="openConfirmDialog(item)" class="mr-2"/>
                        <Button :btnType="'Cancel'" :color="'primary'" :label="'Reject'" @onClick="openCancelModal(item)"/>
                    </td>
                </tr>
              </tbody>
          </v-simple-table>
           <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField  v-model="formData.comments" :label="'Comment'"/>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                  :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :disabled="!valid"
                  class="mr-4"
                />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="isLoading"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import { salaryComponentService } from '@/services'

import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
export default {
  components: {
    Button,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      isLoading: false,
      loadingData: false,
      rejectDialog: false,
      dialog: false,
      pendingApprovers: [],
      alertMessage: '',
      valid: false,
      alert: false,
      alertType: '',
      approvers: [],
      comment: '',
      formData: {
        comments: ''
      }
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    getAllPendingApprovers () {
      this.loadingData = true
      salaryComponentService.getPendingModule()
        .then(result => {
          this.pendingApprovers = result.data
        })
        .catch(() => { })
      this.loadingData = false
    },
    openConfirmDialog (item) {
      this.dialog = true
      this.item = item
    },
    openCancelModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    approve () {
      this.isLoading = true
      let data = []
      data.push({
        id: this.item.id,
        Status: 1,
        Comments: 'Approved'
      })

      salaryComponentService.approveModule(data).then(() => {
        this.showAlertMessage('Approve Module successful', 'success')
      })
        .catch(() => {
          this.isLoading = false
          this.dialog = false
        }).finally(() => {
          this.getAllPendingApprovers()
          this.isLoading = false
          this.dialog = false
        })
    },
    reject (item) {
      this.isLoading = true
      let data = []
      data.push({
        id: this.item.id,
        Status: 2,
        Comments: this.formData.comments
      })

      salaryComponentService.approveModule(data).then(() => {
        this.showAlertMessage(`Reject Module successful`, 'success')
      })
        .catch(() => {
          this.isLoading = false
          this.rejectDialog = false
        }).finally(() => {
          this.getAllPendingApprovers()
          this.isSaving = false
          this.rejectDialog = false
        })
    }
  },
  mounted () {
    this.getAllPendingApprovers()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }

  .personal-name {
    text-align: left;
    font-weight: 600;
    font-size: 14px !important
  }

  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .allowance-day {
     color: blue;
     text-decoration: underline;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
